import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FiLock } from "react-icons/fi";
import { motion } from "framer-motion";

const EncryptedButton: React.FC = () => {

    const navigate = useNavigate();

    const handleClick = () => {

        setTimeout(() => {
            navigate('/catalog')
        }, 1000)
    }


    return (
        <div onClick={handleClick}>
            <EncryptButton />
        </div>
    );
};

const TARGET_TEXT = "Выбрать кошелек";
const CYCLES_PER_LETTER = 2;
const SHUFFLE_TIME = 50;

const CHARS = "!@#$%^&*():{};|,.<>/?";

const EncryptButton: React.FC = () => {
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const [text, setText] = useState<string>(TARGET_TEXT);

    const scramble = (): void => {
        let pos = 0;

        intervalRef.current = setInterval(() => {
            const scrambled = TARGET_TEXT.split("")
                .map((char, index) => {
                    if (pos / CYCLES_PER_LETTER > index) {
                        return char;
                    }

                    const randomCharIndex = Math.floor(Math.random() * CHARS.length);
                    const randomChar = CHARS[randomCharIndex];

                    return randomChar;
                })
                .join("");

            setText(scrambled);
            pos++;

            if (pos >= TARGET_TEXT.length * CYCLES_PER_LETTER) {
                stopScramble();
            }
        }, SHUFFLE_TIME);
    };

    const stopScramble = (): void => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        setText(TARGET_TEXT);
    };

    return (
        <motion.button
            whileHover={{
                scale: 1.025,
            }}
            whileTap={{
                scale: 0.975,
            }}
            onMouseEnter={scramble}
            onMouseLeave={stopScramble}
            className="group relative overflow-hidden rounded-lg border-[1px] border-neutral-500 bg-card-dark px-4 py-2 font-mono font-medium uppercase text-neutral-200 transition-colors hover:text-green-500"
        >
            <div className="relative z-10 flex items-center gap-2">
                <FiLock />
                <span>{text}</span>
            </div>
            <motion.span
                initial={{
                    y: "100%",
                }}
                animate={{
                    y: "-100%",
                }}
                transition={{
                    repeat: Infinity,
                    repeatType: "mirror",
                    duration: 1,
                    ease: "linear",
                }}
                className="duration-300 absolute inset-0 z-0 scale-125 bg-gradient-to-t from-green-400/0 from-40% via-green-600/100 to-green-800/0 to-60% opacity-0 transition-opacity group-hover:opacity-100"
            />
        </motion.button>
    );
};

export default EncryptedButton;

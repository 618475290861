import { FC } from "react";

const Dashboard: FC = ({}) => {

    return (
        <>
            Dashboard
        </>
    )
}

export default Dashboard
import { FC } from "react";

const Login: FC = ({}) => {

    return (
        <>
            Login
        </>
    )
}

export default Login